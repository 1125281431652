<template>
  <v-layout>
    <v-flex style="margin: 20% auto;" xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>사용자 인증</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template>
              <v-btn icon large target="_blank">
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </template>
            <span>Source</span>
          </v-tooltip>
          <v-tooltip right>
            <template>
              <v-btn icon large target="_blank">
                <v-icon>mdi-codepen</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field label="Login" v-model="id" type="text"></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              name="password"
              type="password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-on:click="login" color="red">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import login from "../../store/login";
import Vue from "vue";
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      id: null,
      password: null
    };
  },
  mounted() {
    document.addEventListener("keydown", e => {
      if (e.key === "Enter") {
        this.login();
      }
    });
  },
  methods: {
    login: async function() {
      const url = `https://${process.env.VUE_APP_API_BASE_URL}:${process.env.VUE_APP_API_BASE_PORT}/auth/authenticate`;
      console.log(url)
      await axios
        .post(url, {
          username: this.id,
          password: this.password
        })
        .then(res => {
          this.$store.dispatch("setLoginToken", res.data.jwt);
          this.$router.push({ name: "Index" });
        })
        .catch(() => {
          alert(`로그인을 다시 시도해주세요.`);
        });
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  body > div > div.layout {
    position: static !important;
    width: 265px !important;
    min-width: 0px !important;
    margin: 20% auto;
  }
}
</style>
